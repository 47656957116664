.textOrange {
  color: #f8a217;
}
.textGreen {
  color: #00af43;
}
.textRed {
  color: #BE2C2F;
}
.textBlue {
   color: #115d97 !important;
}
img.acessImg {
 width: 46px;
}


.tab-user-card {
 .nav-pills {
   background: #ecf0f5;
   padding: 20px;
   border-radius: 5px;
 }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
 background: $primary-color;
 border-color: $primary-color;
}

.q-view{
 width: 100vw;
 left: 0;
 &.active{
   .content {
     left: auto;
     position: fixed;
     .media-object {
       margin-bottom: 10px;
     }
   }
 }
}

.help-desk {
  .media-object {
     margin-bottom: 10px;
  }
}

.navbar-wrapper {
 .navbar-content {
   &.next-scroll {
     height: 100vh;
   }
 }
}

@media only screen and (max-width: 1199px) {
 .hover-blk {
   display: none;
 }
}

.qview-modal {
 &.fade {
   .q-view-modal.modal-dialog {
     transform: translateX(550px);
   }
 }
 &.show {
   padding-right: 0 !important;
   .q-view-modal.modal-dialog {
     transform: none;
   }
 }
}
.q-view-modal {
 margin: 0 0 0 auto !important;
 .modal-content {
   min-height: 100vh;
   border-radius: 0;
   border: none;

   .modal-body {
     flex: 1 1 100%;
     height: 100%;

     .trumbowyg-box,
     .trumbowyg-editor {
       min-height: 110px;
     }
   }
 }
 @media (min-width: 576px) {
   .modal-dialog {
     max-width: 550px;
     margin: 0 0 0 auto;
   }
 }
}
.pcoded-header .dropleft .dropdown-toggle::before {
  display: none;
}
input.form-control[type='radio']{
  height: 25px;
}

.logo {
  width: 132px;
}
.xifi_logo {
 width: 76px;
}
.xifi_logo_white {
 width: 40px;
}
.janwanilogo {
 width: 140px;
}
.cursor_pointer {
  cursor: pointer;
}
.aut-bg-img .fix_height {
  padding: 70px 40px;
  width: 460px;
  height: 920px;
  overflow-y: auto;
}

.btn-primary:hover {
 background-color: #F8A217;
 border-color: #F8A217;
}
.centerhead {
 top: 10px;
 width: 160px;
}
.centerhead span {
 font-size: 16px;
 font-weight: bold;
 top: 5px;  
}
.centerhead p {
 bottom: -39px;
 font-size: 14px;
 letter-spacing: 0.3px;
}
.logoLeft {
 top: 10px;
}
.gender-radio input{
  height: 1.2em;
}

img.janwani_logo {
 width: 176px;
}
.middleContent img.janwani_logo {
 width: 20rem;
}
img.pmwani_logo {
 width: 176px;
}
.logo_top_section {
 // padding-right: 400px;
 top: 0;
 left: 0;
}
a.dud-logout .icon-log-out {
 cursor: pointer;
}



// New Subscription design
.subscriptionBox img {
  width: 380px;
  height: 520px;
}
.subscriptionBox .subsContent {
  top: 7.8rem;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 50px;
}
.subscriptionBox h2 {
  font-size: 2.4rem;
}
.subscriptionBox .subsContent h2 {
  font-size: 2.2rem;
}
.subscriptionBox .offBox {
  font-size: 20px;
}
.subscriptionBox .bonusBox h3 {
  font-size: 2.4rem;
}
.subscriptionBox .plusBonus label {
  font-size: 1.3rem;
}
.subscriptionBox .plusBonus span {
  top: -7px;
}
.subscriptionBox .bestBox p span {
  font-size: 24px;
  position: relative;
  top: 3px;
}
.subscriptionBox .bestBox p {
  font-size: 14px;
  line-height: 22px;
}
.subscriptionBox .checkBtn {
  width: 36px;
  height: 36px;
  background: #d5d5d5;
  cursor: pointer;
}
.subscriptionBox .checkBtn i {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
}
.subscriptionBox span.checkBtn.active {
  background: #00af43;
}
.subscriptionBox {
  margin: 2% 1%;
}
.centerhead a.b-brand {
  background: #fff;
  padding: 8px 10px;
  border-radius: 30px;
}
.rightLogo a.b-brand {
  padding: 8px 16px;
  background: #fff;
  border-radius: 30px;
}
.leftLogo a.b-brand {
  background: #fff;
  padding: 6px 6px;
  border-radius: 12px;
}
h2.subscribHeading {
  font-size: 2.4rem;
}

button.buyBtn {
  background: #115d97;
  width: 152px;
  height: 46px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 42px;
  border-radius: 46px;
  transition: all 0.3s ease;
}
button.buyBtn:hover {
 background: #f8a217;
}

// Marketing page CSS----------------
.marketing_main .img-thumbnail img {
 height: 200px;
 width: 150px;
}
.marketing_main .img-thumbnail {
   width: 100%;
   height: 200px;
}
.marketing_main .img-thumbnail img {
   height: 100%;
   width: 100%;
   object-fit: cover;
}
.editIcon {
   background: #fff;
   width: 26px;
   height: 26px;
   border-radius: 5px;
   top: 4px;
   right: 4px;
   cursor: pointer;
}
.marketing_main .img-thumbnail img {
   height: 100%;
   width: 100%;
   object-fit: cover;
}
.editIcon i {
   font-size: 20px;
}




h5.subHeading {
 font-size: 1.4rem !important;
}
h6.minHeading {
   font-size: 1.1rem;
}
.subscriptionBox .subsContent h4 {
 font-size: 22px;
}

.logoBlock {
 border: 2px solid #818d97;
 border-radius: 10px;
 padding: 3px;
}
.logoBlock h5 {
 font-size: 16px;
}
.subscriptionContent {
 max-width: 720px;
 margin: 0 auto;
}
.subscriptionContent p.desp {
 font-weight: bold;
 font-size: 18px;
}
.pack_detail {
 background: #115d97;
 display: inline-block;
 padding: 10px 16px;
 border-radius: 8px;
 border: 1px solid #333;
}
.pack_detail p {
 margin: 0 0 3px;
 font-size: 15px;
 color: #fff;
}
.step_block h4 {
 font-size: 20px;
}
.step_block h3 {
 font-size: 20px;
}
.step {
 height: 230px;  
}
.step_block {
 border: 2px solid #657581;
 border-radius: 10px;
 padding: 3px;
}
.reg_block h2 {
 font-size: 30px;
 color: #fff;
 background: #115d97;
 padding: 0px 0 4px;
}
.threeImg {
 border-bottom: 5px solid #ff9000;
}
p.copyright {
 font-size: 14px;
 color: #000;
 font-weight: 600;
}

.select .form-control {
 padding-left: 0;
 padding-right: 0px;
 height: auto;
 border: none;
}
.select .form-control label {
 font-weight: bold;
}
.select .form-control select, .select .form-control input, .select .form-control textarea {
  width: 100%;
  height: auto;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px !important;
}
.select .form-control textarea {
  min-height: 115px;
}
.select .form-control input {
 padding: 0 6px;
}

.error {
  font-size: 13px;
  color: red;
  margin: 5px 0 0 0;
}
.field-radiobutton {
  display: flex;
  grid-gap: 10px;
}
.phone input.form-control {
 width: 100%;
 height: 32px;
}
a.plus {
 font-size: 26px;
}
a.minus {
 font-size: 38px;
 height: 6px;
 line-height: 0px;
}



// Distributor login page CSS-------------------

html,body,#root {
 height: 100%;
}

.signMain {
 background-image: url("../../images/auth/img-auth-big.jpg");
 background-size: cover;
 background-attachment: fixed;
 background-position: center;
 height: 100%;
}
form.formContentMain {
 background: #fff;
 box-shadow: 0 0px 10px #dfdddd;
 border-radius: 36px;
 padding: 20px 26px;
 width: 100%;
 max-width: 70%;
 margin: 2rem auto 0;
}
form.formContentMain h1 {
 font-size: 36px;
}
.form-control {
 padding: 0;
 margin-bottom: 26px;
 height: auto;
 border: none;
}
.form-control input,
.form-control textarea,
.form-control select {
  border: 1px solid #ced4da;
 width: 100%;
 padding: 10px;
 border-radius: 5px;
}
.form-control textarea {
  height: auto;
}
.noName h5 {
 color: #ced4da;
 font-size: 20px;
}
.orderBox.active .noName h5,
.orderBox.complete .noName h5 {
 color: #5FC36C;
}
.noName span {
 width: 32px;
 height: 32px;
 border: 1px solid #ced4da;
 color: #ced4da;
 font-size: 22px;
}
.orderBox.active .noName span {
 border: 1px solid #5FC36C;
 color: #5FC36C;
}
.orderBox.complete .noName span {
  border: 1px solid #5FC36C;
  color: #fff;
  background-color: #5FC36C;
}
span.line {
 height: 5px;
 background: #C2CBD2;
 margin-top: 14px;
}
.orderBox {
 width: 25%;
}
.orderBox.last {
 width: 96px;
}
form.formContentMain h2 {
 font-size: 26px;
}
form.formContentMain h6 {
 font-size: 16px;
}
.selectOption:before {
  content: '';
  position: absolute;
  border: solid #37474f;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 14px;
  right: 24px;
  pointer-events: none;
}
.selectOption {
  position: relative;
}
.selectOption select {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
.nextprevBtn button {
  width: 120px;
  height: 42px;
}
button.p-button.p-component:hover {
 background: #e71146;
 border-color: #e71146;
}
.viewContract button.p-button {
 background: #FB2056;
 border: 1px solid #FB2056;
}
.viewContract button.p-button span {
 font-weight: 500;
 line-height: 20px;
 font-size: 16px;
 text-transform: uppercase;
}
.viewContract .card {
 width: 150px;
 height: 80px;
 margin: 24px auto 10px;
}
p.clickView {
 margin: 0 0 36px;
 font-size: 16px;
}
// .field-checkbox .p-checkbox {
//  position: relative;
//  top: 2px;
// }
.field-checkbox label {
 font-size: 16px;
 margin: 0 0 0 6px;
}p.sign {
 font-size: 13px;
 color: #a1a1a1;
}
a.textPink {
 color: #FB2056;
}
a.textPink:hover {
 color: #e71146;
}
.p-dialog .p-dialog-content {
 border-radius: 0 0 6px 6px;
}
.p-dialog .p-dialog-content p {
 font-size: 15px;
}

p.fileUpload {
  font-size: 16px;
  margin: 10px 0 0 0;
}

.userDetails p, .userDetails label {
  font-size: 15px;
  margin: 0px 0 12px;
  font-weight: bold;
}

.pymtBill h1 {
  font-size: 30px;
  box-shadow: 0 0 14px #d2dee7;
}




// .wrap {
//   background-color: #2e4261;
// }

// .custom-file {
//   width: auto;
//   height: 3.3125rem;
//   cursor: pointer;
//   cursor: pointer;
// }
// .custom-file-input {
//   height: 3.3125rem;
// }
// .custom-file-label {
//   display: block;
//   background: none;
//   border: none;
//   color: #fff;
//   line-height: 3.3125rem;
//   height: 3.3125rem;
//   padding: 0 120px 0 0;
//   margin-bottom: 0;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
// }
// .custom-file-label::after {
//   height: 3.3125rem;
//   line-height: 3.3125rem;
//   padding: 0 1.875rem;
//   color: #fff;
//   content: "Choose file";
//   border-radius: 3px;
//   background-color: #536480;
//   box-shadow: 0 2px 2px #000000;
//   border-left: 0;
// }


.signpad{
  border: 2px solid #1b1b1b;
  border-radius: 20px
}


.signature-box{
  position: relative;
  display: inline-block;

  .signbutton{
    border-radius: 9px;
    width: auto;
    height: auto;
    color: white;
    background-color: #ff8c00;
    border: none;
    position: absolute;
    top: 10px;
    flex-direction: row;
    right: 10px;
    z-index: 1;
    font-size: 12px;
    padding: 2px 8px;
    font-weight: bold;

      &:hover{
        background-color: #0d6499;
      }
      
  }

  .signature-save{
        flex-direction: row;
        left: 10px;
        right: inherit;
  }
}

.signature-text-box{
  .left-arrow{
    width: 3rem;
    height: 4rem;
  }

  p{
    font-size: 16px;
  }
}


.profile_capitalize{
  text-transform: capitalize;
}


// .row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

// New CSS start
.custom_profile_form {
  .form-control {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    position: relative;
    border: none;
    border-radius: 0;
    height: auto;
    label {
      font-weight: bold;
      padding-top: calc(0.625rem + 1px);
      padding-bottom: calc(0.625rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.5;
      @media(min-width: 576px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media(max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    input {
      display: block;
      width: 100%;
      height: calc(1.5em + 1.25rem + 2px);
      padding: 0.625rem 1.1875rem;
      font-size: .875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #ced4da;
      background: no-repeat bottom,50% calc(100% - 1px);
      background-size: 0 100%,100% 100%;
      transition: background 0s ease-out;
      &:focus {
        border-bottom-color: transparent;
        background-size: 100% 100%,100% 100%;
        transition-duration: .3s;
        box-shadow: none;
        background-image: linear-gradient(0deg,#115d97 2px,rgba(70,128,255,0) 0),linear-gradient(0deg,#ced4da 1px,rgba(206,212,218,0) 0);
        color: #495057;
        background-color: #fff;
        border-color: #3e9fe9;
        outline: 0;
      }
      @media(min-width: 576px) {
        flex: 0 0 75%;
        max-width: 75%;
      }
      @media(max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
// New CSS end





//New Css For Reset Password And UI theme
.custom_sign_in_up {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .row {
    flex-grow: 1;
    .custom-main {
      height: calc(100% - 146px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media(max-width: 576px) {
        height: auto;
      }
    }
    .custom-sign-screen {
      height: 100%;
    }
    .custom-sign-block {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 70px 40px;
      box-shadow: 0 0 20px #ccc;
      background: #fff;
      overflow-y: auto;
      @media(max-width: 991px) {
        margin-bottom: 50px;
        height: auto;
        padding: 30px;
      }
    }
  }
}
.custom-bg-img {
  background-image: url(./../../../assets/images/auth/img-auth-big.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.custom-top-logos {
  figure {
    margin: 0;
    padding: 20px;
    @media(max-width: 576px) {
      text-align: center;
    }
    img {
      max-width: 176px;
      width: 100%;
      &.xifi_logo {
        width: 76px;
      }
    }
  }
  .text-under-logo {
    text-align: center;
    h5, span {
      color: #115d97;
      margin-bottom: 0;
      font-size: 14px;
    }
    h5 {
      font-size: 16px;
    }
  }
}
.custom-main-logo {
  text-align: center;
  margin: 0 auto;
  background: rgb(255 255 255 / 60%);
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 20px #ccc;
  @media(max-width: 991px) {
    margin-top: 80px;
    margin-bottom: 20px;
  }
  @media(max-width: 576px) {
    margin-top: 20px;
  }
  img {
    max-width: 300px;
  }
}

.atc {
  margin-top: 37px;
}
.custom_rts {
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
    margin-left: 10px;
    cursor: pointer;
  }
}

.blockBlue {
 background-image: url(../../../assets/images/shape1.png);
 background-size: 100% 100%;
 background-repeat: no-repeat;
 padding: 100px 0;
}
.blockGreen {
  background-image: url(../../../assets/images/shape2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 100px 0;
}
.blockOrange {
  background-image: url(../../../assets/images/shape3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 100px 0;
}

.step_block {
  .step {
    @media(max-width: 767px) {
      width: 100%;
      height: auto;
    }
  }
  .custom_plus {
    @media(max-width: 767px) {
      width: 100%;
      max-width: 30px;
      margin: 30px 0;
    }
  }
}

// Sign Up CSS Start
.custom_sign_main {
  form.formContentMain h2 {
    font-size: 22px;
  }
  .viewContract .card {
    width: 280px;
    height: auto;
  }
  form.formContentMain h1 {
    font-size: 26px;
    @media(max-width: 992px) {
      font-size: 24px;
      margin-bottom: 20px !important;
    }
  }
  .signature-box, .signature-box canvas, .signature-box img {
    width: 100%;
  }
  .cust_upload {
    position: relative;
    margin-bottom: 10px;
  }
  .uploadBox .uploadBtn {
    pointer-events: none;
    top: 0px;  
  }
  .uploadBox input[type="file"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
  button.uploadBtn {
    background: #FB2056;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0 0 10px #979595;
    transition: all 0.3s ease;
    width: 100%;
  }
  button.uploadBtn:hover {
    background: #eb0e45;
  }
  button.uploadBtn span {
    font-size: 18px;
    top: -2px;
  }
  .container {
    @media(max-width: 1200px) {
      max-width: 100%;
    }
  }
  form.formContentMain {
    @media(max-width: 992px) {
      max-width: 90%;
    }
  }

  .orderBlock {
    @media(max-width: 767px) {
      overflow-x: auto;
      position: relative;
      &:before {
        content: '';
        height: 5px;
        background: #C2CBD2;
        margin-top: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        z-index: 0;
        width: 100%;
      }

      .orderBox {
        width: auto;
        position: relative;
        min-width: 130px;
        .noName {
          background: #ffffff;
          width: 90%;
        }
        span.line {
          display: none;
        }
      }
    }
  }
  .signature-text-box {
    .left-arrow {
      @media(max-width: 767px) {
        transform: rotate(90deg);
      }
    }
  } 
}

.p-dialog-enter-done {
  width: 90% !important;
  max-width: 800px;
}

.dom_popup {
  .responsive-table {
    overflow: auto;
    padding: 5px;
  }
  table {
    @media(max-width: 767px) {
      td {
        min-width: 100px;
      }
    }
  }
  h2 {
    &.Title {
      font-size: 26px;
      margin-bottom: 20px;
      @media(max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  h3 {
    &.heading {
      font-size: 26px;
      margin-bottom: 10px;
      @media(max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  .subtitle {
    text-align: center;
  }
}


// Sign Up CSS End
// New CSS End

// Table Data Aligment css

.table_datacss {
  text-align: center;
  vertical-align: middle;
}

.modal-dialog {
  max-width: 800px;
  max-height: 90%;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  border: 0;
}

//verifyAccount Model css

.verify-account-model {
	max-width: 500px !important;
	margin: 1.75rem auto !important;
}

// franchise payment css start
.franchise-payment {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  @media(max-width: 767px) {
    border-radius: 20px;
  }
  .payment-top-details {
    max-width: 80%;
    margin: 0 auto;
    @media(max-width: 1200px) {
      max-width: 100%;
    }
  }
  h2 {
    font-size: 36px;
    color: #191919;
    text-transform: uppercase;
    margin-bottom: 50px;
    @media(max-width: 1300px) {
      font-size: 32px;
      margin-bottom: 20px;
    }
    @media(max-width: 1200px) {
      font-size: 28px;
    }
  }
  .final-step-offer {
    h3 {
      color: #fb2056;
      font-size: 28px;
      margin-bottom: 30px;
      @media(max-width: 1300px) {
        font-size: 24px;
        margin-bottom: 20px;
      }
      @media(max-width: 1200px) {
        font-size: 22px;
      }
      span {
        text-transform: uppercase;
      }
    }
    .final-offer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media(max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      h2 {
        margin-bottom: 20px;
        color: #0d6499;
        @media(max-width: 1200px) {
          font-size: 24px;
        }
        @media(max-width: 767px) {
          font-size: 22px;
        }
      }
      h4 {
        color: #ff8c00;
        margin-bottom: 20px;
        font-size: 28px;
        @media(max-width: 1200px) {
          font-size: 22px;
        }
        @media(max-width: 767px) {
          font-size: 20px;
        }
      }
    }
    .franchise-fee {
      h3 {
        span {
          text-decoration: line-through;
        }
      }
    }
  }
  .payment-package-details {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    @media(max-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media(max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .payment-package {
      background: #d2d2d2;
      padding: 70px 20px;
      text-align: left;
      background-size: cover;
      background-position: center center;
      color: #fff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 50%);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        pointer-events: none;
      }
      h2 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #fff;
        position: relative;
        z-index: 2;
        line-height: 2;
        margin-bottom: 10px;
      }
      p {
        font-size: 20px;
        position: relative;
        z-index: 2;
        line-height: 2;
        margin-bottom: 0;
      }
    }
  }
}
// franchise payment css end

.uploadProPic {
  button {
    position: relative;
    cursor: pointer; 
    input[type="file"] {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 0;
      height: 100%;
      opacity: 0;
      cursor: pointer; 
    }
  }
}

//React Data table Cumponent Head style
.fbhklE{
  font-weight: bold !important;
  
}
.lnndaO {
  white-space: pre-line !important;
}